import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulNoticia')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const body_options = {
      renderNode: {
        "embedded-asset-block": (node) => {
          const alt = node.data.target.fields.title['en-US']
          const url = node.data.target.fields.file['en-US'].url
          return <img src={url} alt={alt} />
        }
      }
    }

    return (
      <Layout>
        <Helmet>
          <title>{post.titulo} | {siteTitle}</title>
          {post.texto_preview  &&
          <meta name="description" content={post.texto_preview.texto_preview} />
          }
        </Helmet>
        <div className="hero-text">
          <h1 className="section-headline">{post.titulo}</h1>
        </div>
        <div className="news-text row">
          <div className="columns small-12 medium-8 medium-offset-2">
            <p className="created-at"><small>{post.createdAt}</small></p>
            { documentToReactComponents(post.texto.json, body_options) }
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulNoticia(slug: { eq: $slug }) {
      titulo
      createdAt(formatString: "DD/MM/YYYY")
      texto {
        json
      }
      texto_preview {
        texto_preview
      }
    }
  }
`
